import { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';

import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import './Header.scss';

import Logo from '../../assets/public/softMachineLogo.png'

const Header = (props) => {

    const theme = useTheme();
    const [open, setOpen] = useState(false);
  
    let location = useLocation();
    let navigate = useHistory().push;

    const drawerWidth = 240;

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    }));

    const navigateHandler = (path) => {
        navigate(path);
        setOpen(false);
    }
    
    return (
        <>
            <div className='nav'>
                <div className='left'>
                    <div className='logo'>
                        <IconButton
                            className='menuIcon'
                            color='inherit'
                            aria-label='open drawer'
                            onClick={() => setOpen(true)}
                            edge='start'
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <img src={Logo} alt='ARGUS' onClick={() => navigate('/')} />
                    </div>
                </div>
                <div className='right'>
                    <div className='buttonsCon'>
                        <button id={location.pathname === '/' ? 'activeBtn' : undefined} onClick={() => navigate('/')}>Home</button>
                        <button id={location.pathname === '/aboutus' ? 'activeBtn' : undefined} onClick={() => navigate('/aboutus')}>About Us</button>
                        <button id={location.pathname === '/product' ? 'activeBtn' : undefined} onClick={() => navigate('/product')}>Our Product</button>
                        <button id={location.pathname === '/clients' ? 'activeBtn' : undefined} onClick={() => navigate('/clients')}>Our Clients</button>
                        {/* <button id={location.pathname === '/privacypolicy' ? 'activeBtn' : undefined} onClick={() => navigate('/privacypolicy')}>Privacy Policy</button> */}
                        <button id={location.pathname === '/contactus' ? 'activeBtn' : undefined} onClick={() => navigate('/contactus')}>Contact Us</button>
                    </div>
                </div>
            </div>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant='temporary'
                anchor='left'
                open={open}
                onClose={() => setOpen(false)}
            >
                <DrawerHeader>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <img className='drawerLogo' src={Logo} alt='ARGUS' onClick={() => navigate('/')} />
                        </ListItemButton>
                    </ListItem>
                    <IconButton onClick={() => setOpen(false)}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItem disablePadding onClick={() => navigateHandler('/')}>
                        <ListItemButton>
                            <ListItemText id={location.pathname === '/' ? 'activeBtn' : 'materialBtn'} primary='Home' />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding onClick={() => navigateHandler('/aboutus')}>
                        <ListItemButton>
                            <ListItemText id={location.pathname === '/aboutus' ? 'activeBtn' : 'materialBtn'} primary='About Us' />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding onClick={() => navigateHandler('/product')}>
                        <ListItemButton>
                            <ListItemText id={location.pathname === '/product' ? 'activeBtn' : 'materialBtn'} primary='Our Product' />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding onClick={() => navigateHandler('/clients')}>
                        <ListItemButton>
                            <ListItemText id={location.pathname === '/clients' ? 'activeBtn' : 'materialBtn'} primary='Our Clients' />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding onClick={() => navigateHandler('/contactus')}>
                        <ListItemButton>
                            <ListItemText id={location.pathname === '/contactus' ? 'activeBtn' : 'materialBtn'} primary='Contact Us' />
                        </ListItemButton>
                    </ListItem>
                    {/* <ListItem disablePadding onClick={() => navigateHandler('/privacypolicy')}>
                        <ListItemButton>
                            <ListItemText id={location.pathname === '/privacypolicy' ? 'activeBtn' : 'materialBtn'} primary='Privacy Policy' />
                        </ListItemButton>
                    </ListItem> */}
                </List>
            </Drawer>
        </>
    );
};

export default Header;
