import React from 'react';
import './Footer.scss';

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="footer__content">
        <p>
          All rights reserved &copy; <span className="footer__brand">Softmachine</span> {currentYear}
        </p>
        <div className="footer__links">
          <a href="/privacypolicy">Terms & Privacy Policy</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
